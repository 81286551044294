import { abstractAPIServicesClass } from './abstractAPIServiceClass';

class cloudAPIServiceClass extends abstractAPIServicesClass {
  constructor() {
    console.log(process.env);
    super('/', process.env.VUE_APP_API_HOST);
  }

  postAuthToken(params) {
    return this.http.post('/auth/token', params)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  getVersion(cuid, token) {
    return this.http.get(`v3/apis/${cuid}/device/system/version?access_token=${token}`).then(this.handleResponse.bind(this))
    .catch(this.handleError.bind(this));
  }

  postCommand(cuid, token, params) {
    return this.http.post(`v3/apis/${cuid}/device/system/command?access_token=${token}`, params)

  }
  postVersion(cuid, token, params) {
    return this.http.post(`v3/apis/${cuid}/device/system/version?access_token=${token}`, params)
    .then(this.handleResponse.bind(this))
    .catch(this.handleError.bind(this))
  }
}


export const cloudAPIService = new cloudAPIServiceClass();
