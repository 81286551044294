<template>
  <v-container data-app>
    <v-data-table
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
        }"
      :items-per-page="30"
    >

      <template v-slot:[`item.environment`]="{ item }">
        <v-select
          v-model= "item.environment.selected"
          :items= "item.environment.options"
        ></v-select>

      </template>

      <template v-slot:[`item.cuid`]= "{ item }">
        <v-text-field v-model="item.cuid"> {{item.cuid}}</v-text-field>
      </template>
      <template v-slot:[`item.actions`]= "{ item }">
        <v-row>
          <div
            v-for="action in item.actions"
            :key="action.name"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    small
                    class="ma-1 green"
                    :disabled="action.enable==false"
                    @click.stop="onActionButtonClick(item, action)"
                  >
                    <v-icon>mdi-{{ action.name }}</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>{{ action.tooltip }}</span>
            </v-tooltip>
          </div>
        </v-row>
      </template>
    </v-data-table>

  </v-container>
</template>

<script>

import { cloudAPIService } from "../services/cloudAPIServiceClass"

  export default {
    name: 'HelloWorld',
    methods: {
      onActionButtonClick(item, action) {
        var that = this;
        var token = null;
        console.log(item.cuid)

        action.enable=false
        cloudAPIService.postAuthToken(that.userLoginInfo).then( resp => {
          console.log(resp)
          token=resp.access_token;
          var dev= {
            version:2,
            apiKey: "S_20221101123457",
            apiSecret: "FD3117BEEA1184D57122AE9ED1AF110FF885BFB9",
            endpoint: `https://qcall-${item.environment.selected}.ddc.ai/v2/device/scanner`
          }
          var prod= {
            version:2,
            apiKey: "36fa2d3b-a5c1-49aa-b6ca-1df7cb2df7b2",
            apiSecret: "G3JZdoSGKFxDckxz54QnU9spqYE42E8q",
            endpoint: `https://qcall-${item.environment.selected}.ddc.ai/v2/device/scanner`
          }
          var param = dev

          if (item.environment.selected === "production") {
            prod.endpoint = "https://qcall.ddc.ai/v2/device/scanner"
          }

          if (item.environment.selected === "production" || item.environment.selected === "rc" || item.environment.selected === "beta") {
            param = prod
          }

          var cmd= {
            cmd: `echo -n '${JSON.stringify(param)}' > /tmp/IIOT_data/barcode.json`
          }

          console.log(cmd)
          return cloudAPIService.postCommand(item.cuid, token, cmd)
        }).then( resp => {
          console.log(resp)
          return cloudAPIService.getVersion(item.cuid, token)          
        }).then( resp => {
          var version= {
            jffs2:{
              sha1sum:"9da062027d21026004c23762d6841ee2f604a953",
              url:"http://devicefiles.iiot.io/iNodeEAI2502/jffs2/v1.1.32"
            },
            reboot:true
          }
      
          console.log(resp)
          action.enable=false
        
          return cloudAPIService.postVersion(item.cuid, token, version)
        }).then( resp => {          
          console.log(resp)
          action.enable=true
        }).catch( error => {
          console.log(error)
          action.enable=true
        })
        
      },
    },
    mounted: function() {
    },
    data(){
    return {
      cuid: "",
      items: [
          {
            environment: {
              selected: "dev",
              options: [
                "dev",
                "staging",
                "beta",
                "production"
              ]
            },
            cuid: null,
            actions: [ {
              enable: true,
              name: "cloud-arrow-down",
              tooltip: "upgrade firmware"
            }
          ]
          }
      ],
      userLoginInfo: {
        "username":"recardo",
        "password":"asdfqwer",
        "client_id":"testclientid",
        "client_secret":"12345678",
        "grant_type":"password"
      },
      headers: [
          {
            sortable: false,
            text: '環境',
            value: 'environment',
            width: '12%',
            class: 'text-h6 grey lighten-2',
          },
          {
            sortable: false,
            text: 'cuid',
            value: 'cuid',
            width: '30%',
            class: 'text-h6 grey lighten-2',
          },
          {
            sortable: false,
            text: '操作',
            value: 'actions',
            class: 'text-h6 grey lighten-2',
          },
        ],
    }
  }
}
</script>
