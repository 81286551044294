//import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';

export function isAxiosError(value) {
  return typeof value?.response === 'object';
}

export class abstractAPIServicesClass {
  
  constructor(path, baseURL =  '/') {
    if (path) {
      baseURL += path;
    }
    this.http = axios.create({
      baseURL,
      // ... further stuff, e.g. `withCredentials: true`
    });
    this.http.defaults.headers.common['Accept'] = 'application/json;charset=UTF-8';
    this.http.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
  }


  createParams(record) {
    const params = new URLSearchParams();
    for (const key in record) {
      if (Object.prototype.hasOwnProperty.call(record, key)) {
        const value = record[key];
        if (value !== null && value !== undefined) {
          params.append(key, value);
        } else {
          console.debug(`Param key '${key}' was null or undefined and will be ignored`);
        }
      }
    }
    return params;
  }

  handleResponse(response) {
    return response.data;
  }

  handleError(error) {
    if (error instanceof Error) {
      if (isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw error;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          console.log(error.request);
          throw new Error(error);
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        throw new Error(error.message);
      }
    }
    throw new Error(error);
  }
}
